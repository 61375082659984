// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from 'vue'
import { defineSliceZoneComponents } from '@prismicio/vue'

export const components = defineSliceZoneComponents({
  about_grow_section: defineAsyncComponent(
    () => import('./AboutGrowSection/index.vue'),
  ),
  about_header_section: defineAsyncComponent(
    () => import('./AboutHeaderSection/index.vue'),
  ),
  about_join_us_section: defineAsyncComponent(
    () => import('./AboutJoinUsSection/index.vue'),
  ),
  about_journey_section: defineAsyncComponent(
    () => import('./AboutJourneySection/index.vue'),
  ),
  about_values_section: defineAsyncComponent(
    () => import('./AboutValuesSection/index.vue'),
  ),
  career_benefit_section: defineAsyncComponent(
    () => import('./CareerBenefitSection/index.vue'),
  ),
  career_header_section: defineAsyncComponent(
    () => import('./CareerHeaderSection/index.vue'),
  ),
  career_header_section2: defineAsyncComponent(
    () => import('./CareerHeaderSection2/index.vue'),
  ),
  career_know_us_section: defineAsyncComponent(
    () => import('./CareerKnowUsSection/index.vue'),
  ),
  career_position_section: defineAsyncComponent(
    () => import('./CareerPositionSection/index.vue'),
  ),
  career_principle_section: defineAsyncComponent(
    () => import('./CareerPrincipleSection/index.vue'),
  ),
  career_why_join_section: defineAsyncComponent(
    () => import('./CareerWhyJoinSection/index.vue'),
  ),
  dark_section: defineAsyncComponent(() => import('./DarkSection/index.vue')),
  hero_section: defineAsyncComponent(() => import('./HeroSection/index.vue')),
  light_green_section: defineAsyncComponent(
    () => import('./LightGreenSection/index.vue'),
  ),
  simple_cta: defineAsyncComponent(() => import('./SimpleCta/index.vue')),
  simple_faq: defineAsyncComponent(() => import('./SimpleFaq/index.vue')),
  simple_footnote: defineAsyncComponent(
    () => import('./SimpleFootnote/index.vue'),
  ),
  simple_text: defineAsyncComponent(() => import('./SimpleText/index.vue')),
  simple_text_partner: defineAsyncComponent(
    () => import('./SimpleTextPartner/index.vue'),
  ),
})
